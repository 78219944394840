.loader.simple-loader {
	background-color: $body-bg;
	height: 100%;
	width: 100%;
	position: fixed;
	place-content: center;
	overflow: hidden !important;
	right: 0px;
	z-index: 1250;
	display: flex;
	align-items: center;
    .loader-body{
        // background: url(../images/loader.gif) no-repeat scroll center center;
        background-size: 20%;
        width: 100%;
        height: 100%;
        position: relative;
    }
	.anim_rotate {
		transform-origin: 70% 58%;
		animation: loader-roate infinite 1.5s;
	}
}

@keyframes loader-roate {
	to {
		transform: translate(0, 0) scale(1) rotate(0deg);
	}
	from {
		transform: translate(0, 0) scale(1) rotate(360deg);
	}
}