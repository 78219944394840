:root {
    --ck-border-radius: 1.25rem;
     --ck-custom-background: $white;
     --ck-color-base-foreground: var(--ck-custom-background);
     --ck-color-focus-border: #f9800880;
     --ck-color-text: var(--ck-color-base-text);;
     --ck-color-shadow-drop: none;
     --ck-color-shadow-inner: none;
}


.ck.ck-button {
    &:hover, &:active, &:focus{
        color: $white !important;
        background-color: $primary !important;
        box-shadow: $input-btn-focus-box-shadow !important;
    }
}
.ck.ck-button.ck-on, a.ck.ck-button.ck-on{
    color: #F98008;
    &:hover, &:active, &:focus{
        color: $dark !important;
        background-color: $primary !important;
        box-shadow: $input-btn-focus-box-shadow !important;
    }
}
.ck.ck-button.ck-on:not(.ck-disabled):hover, a.ck.ck-button.ck-on:not(.ck-disabled):hover{
    color: $dark !important;
        background-color: $primary !important;
        box-shadow: $input-btn-focus-box-shadow !important;
}
.ck.ck-list__item .ck-button.ck-on:not(.ck-disabled){
    background-color: $primary !important;
    color: $white !important;
}
.ck-powered-by{
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
}