#signup-form {
    * {
        font-family: 'Rubik', sans-serif;
        box-sizing: border-box;
    }

    .fs-42 {
        font-size: 42px;
    }

    .header {
        height: 80px;
        .logo_home {
            height: 100%;
            width: 200px;
            padding: 20px 0;
            margin: auto;
        }
    }

    .footer{
        position: initial;
        background : transparent;
    }

    /* ADD BY ACHREF */ 
    .dog-name-input {
        border-bottom: 3px dotted rgba(8, 8, 8, 0.87) !important;
        margin: 25px 0 !important;
        font-weight: 600;
        font-size:28px;
    }
    .dog-name-input::placeholder {
        font-size: 22px ;
    } 
    
    .bilon_chien {
        position: absolute;
        right: 2%;
        top: 0;
        z-index: -1;
        filter: drop-shadow(1px 2px 2px #ddd);
        width: 20vw;
        z-index: -1;
    }

    .table_footer {
        position: absolute;
        right: 2%;
        bottom: 0;
        width: 16vw;
        z-index: -1;
    }

    @media screen and (max-width: 768px) {
        img.chien_doc {
            position: relative !important;
        }
        .dog-name-input::placeholder {
            font-size: 16px !important;
        } 
        .profils_box label {
            background-color: #fff;
        }
        .mt-30 {
            margin-top:30px;
        }
        .header {
            height: 50px;
            .logo_home {
                height: 100%;
                width: 100px;
                padding: 20px 0;
                margin: auto;
            }
        }
    }

    @media screen and (max-width: 1680px) {
        #papipets-form {
            zoom: .85;
        }
    }

    #papipets-form fieldset:not(:first-of-type) {
        display: none
    }

    select#month-select:focus,
    select#year-select:focus {
        background-color: #fafbe9;

    }

    select#month-select,
    select#year-select {
        border: 1px solid #3f130f;
        height: 70px;
        background-color: #fff;
        border-radius: 50px;
        gap: 8px;
        color: #3f130f;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    }

    select#month-select option,
    select#year-select option {
        font-size: 20px;
        color: #3f130f;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    }

    #papipets-form input:not([type='checkbox']),
    #papipets-form textarea,
    select {

        padding-left: 0;
        background: none;
        height: 45px;
        border: none;
        border-bottom: 1px solid rgb(20, 20, 20);
    }

    input::placeholder {
        color: #8b8b8b !important;
    }

    input#accept-conditions {
        width: 20px;
        height: 20px;
    }

    #papipets-form input::placeholder {
        color: #272525;
    }

    #papipets-form input:focus,
    #papipets-form textarea:focus {
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        outline-width: 0
    }
    .modern-arrow-button i {
        margin-left: 10px; /* Adjust spacing between text and icon */
        font-size: 1.2em; /* Adjust icon size */
      }
    #papipets-form .action-button,
    .commander {
    //  width: 180px !important;
    //  border: 2px solid var(--bs-brown);
    //  border-radius: 25px;
    //    cursor: pointer;
    //    color: #fafbe9;
    //    background: var(--bs-brown);
    //    font-size: 20px;
    //    padding: 10px 15px;
    //    margin: 10px 0px 10px 5px;
    //    animation: show-button 1.2s ease-in-out forwards, blink 2.5s infinite linear;
    }
    @keyframes show-button {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      }

      
/* animation Button css Start */

.btn {
	display: inline-block;
	place-items: center;
	background: #3f130f;
	color: #eaeaea;
	text-decoration: none;
	padding: 25px 50px;
	border-radius: 10px;
	text-transform: uppercase;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}
.btn::before {
	content: "";
	position: absolute;
	margin: auto;
	inset: 0;
	width: 130%;
	background-image: linear-gradient(180deg, rgb(249 128 8), rgb(250 251 233));
	height: 60px;
	animation: rotBGimg 4s linear infinite;
	transition: all 0.2s linear;
}
.btn::after {
	content: "";
	position: absolute;
	background: #3f130f;
	inset: 4px;
	border-radius: 8px;
	transition: all 0.3s ease-in-out;
}
.btn span {
	position: relative;
	z-index: 1;
}
.btn:hover {
	color: #1b1e20;
	background: #ffffff;
}
.btn:hover:after {
	background: #e7e9be;
}

@keyframes rotBGimg {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* animation Button css End */



    button.submit-btn {
        width: 70%;
        background: #fff;
        color: var(--bs-brown);
        ;
        border: 2px solid var(--bs-brown);
        ;
        border-radius: 25px;
        cursor: pointer;
        padding: 10px 15px;
        margin: 10px 0px 10px 5px;
    }

    #papipets-form .action-button:hover,
    #papipets-form .action-button:focus,
    button.submit-btn:hover,
    button.submit-btn:focus {
        background-color: #fafbe9;
        color: var(--bs-brown);
        pointer-events: auto;
    }

    #papipets-form .action-button-previous {
        width: 50px;
        height: 50px;
        background: #fff;
        color: var(--bs-brown);
        border: 2px solid var(--bs-brown);
        border-radius: 25px;
        cursor: pointer;
        margin: 10px 20px 10px 5px;
    }

    button.previous svg {
        fill: white;
        width: 35px;
        height: 35px;
    }

    #papipets-form .action-button-previous:hover,
    #papipets-form .action-button-previous:focus {
        background-color: var(--bs-brown);
        color: #fff;
    }

    .card {
        z-index: 0;
        border: none;
        position: relative
    }

    #progressbar {
        overflow: hidden;
        color: lightgrey;
    }



    .sidewalk {
        width: 100%;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        left: 5px;
        z-index: -1;
    }



    .text-orange {
        color: #f98008;
    }

    .text-brown {
        color: var(--bs-brown);
    }

    .text-brown:hover {
        color: var(--bs-brown);
    }

    #progressbar .active {
        color: #fafbe9;
    }

    #progressbar li {
        list-style-type: none;
        width: 20%;
        float: left;
        position: relative;
    }

    #progressbar #account:before {
        font-family: FontAwesome;
        content: "\f13e"
    }

    #progressbar #personal:before {
        font-family: FontAwesome;
        content: "\f007"
    }

    #progressbar #payment:before {
        font-family: FontAwesome;
        content: "\f030"
    }

    #progressbar #confirm:before,
    #progressbar #p2:before {
        font-family: FontAwesome;
        content: "\f00c"
    }

    #progressbar li:before {
        width: 35px;
        height: 35px;
        line-height: 32px;
        display: block;
        color: #fafbe9;
        background: lightgray;
        border-radius: 50%;
        margin: 0 auto 10px auto;
        padding: 1px;
    }

    #progressbar li:after {
        content: '';
        width: 100%;
        height: 2px;
        background: lightgray;
        position: absolute;
        left: 0;
        top: 25px;
        z-index: -1
    }

    #progressbar li.active:before,
    #progressbar li.active:after {
        background: var(--bs-orange);
    }

    .progress {
        z-index: 9;
        height: 8px;
        margin: auto;
    }

    .progress-bar-striped {
        background-image: none;
    }

    .progress.active {
        display: none;
    }

    .progress-bar {
        border-radius: 30px !important;
        border: 1px solid #fafbe9;
    }

    .fit-image {
        width: 100%;
        object-fit: cover
    }


    /* logo */

    .d {
        fill: #fff;
    }

    #heart {
        fill: #fff;
    }


    #d.steps6 {

        fill: #000;
    }

    #heart.steps6 {

        fill: var(--bs-orange);
    }

    h2 span {
        background-color: #3f130f;
        padding: 0 15px;
        color: #f9fae8;
        border-radius: 15px;
    }

    .chien-footer {
        position: absolute;
        left: 5%;
        bottom: 2%;
        width: 15vw;
    }

    #image_slider_1.active-1,
    #image_slider_2.active {
        display: block;
        z-index: -1;
    }

    img.chien_doc {
        position: absolute;
        bottom: 0;
        z-index: -1;
        left: 10px;
        width: 24vw;
        z-index: -1;
    }

    @media screen and (min-width: 1024px) {
        img.chien_b {
            position: absolute;
            bottom: 0;
            z-index: -1;
            right: 25%;
        }
        .btn-next {
            position: fixed;
            right: 15%;
            top: 42%;
        }
    }

    img.chien_b {
        width: 20vw;
        z-index: -1;
    }

    .row.recettes .item label {
        border: 1px solid #f98109;
    }


    img.naturel_img {
        width: 10vw;
        position: fixed;
        top: 25%;
        left: 8%;
    }

    #dogs-steps-1 {
        position: fixed;
        bottom: 0;
        z-index: -1;
        text-align: center;
        /* centrez l'image horizontalement */
        width: 100%;
    }

    img.shap_right {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        z-index: -1;
    }

    .plante-footer {
        position: absolute;
        right: 5%;
        bottom: 2%;
        width: 10vw;
    }

    .img_recette {
        width: 35% !important;
    }

    #dogs-steps-1.removebg1 {
        display: none !important;
    }

    .styled-select {
        margin: 0 auto;
        margin: 0 auto;
        background-color: #3e3d3db5;
        border-radius: 15px;
        padding: 20px 15px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }

    .styled-select .search-input::placeholder,
    .styled-select select option,
    input.search-input {
        color: #fff !important;
    }

    .styled-select select option {
        font-size: 18px;
        margin: 4px 0;
        padding: 4px 12px;
    }

    .styled-select select {
        height: 210px;
        width: 100%;
    }

    .styled-select select:focus {
        color: #555;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .styled-select select::-ms-expand {
        display: none;
    }

    .styled-select select::-moz-placeholder {
        color: #555555;
        opacity: 1;
    }

    .styled-select select::-webkit-input-placeholder {
        color: #555555;
    }

    select option {
        color: #555555;
        border-radius: 15px;
    }

    input.previous.action-button-previous.fixed {
        width: 40% !important;
        height: initial !important;
        padding: 9px 15px !important;
    }

    input.next.action-button.stepsName.fixed {
        width: 40% !important;
    }

    @media screen and (max-width:768px) {


        .styled-select select {
            color: #fff !important;
            height: 45px;
        }
        .center-texte {
            text-align: center !important;
        }
        .gap-t {
            gap: 10px;
        }
        .footer {
            display: none;
        }
        .mb-100 {
            padding-bottom: 30px;
        }

        #papipets-form #steps3 .action-button,
        #papipets-form #steps3 .action-button-previous,
        #papipets-form #steps4 .action-button,
        #papipets-form #steps4 .action-button-previous,
        #papipets-form #steps5 .action-button,
        #papipets-form #steps5 .action-button-previous {
            background: var(--bs-brown);
            color: #fafbe9;
        }

        input.previous.action-button-previous.fixed,
        input.next.action-button.stepsName.fixed {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }
    }

    /* copie de code item pour profils de chien */

    .profils_box label {
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        border-radius: 30px;
        display: block;
        text-align: center;
        margin-bottom: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        position: relative;
        border: 1px solid var(--bs-brown);
        font-size: 26px;
        color: var(--bs-brown);
        cursor: pointer;
        background-color: #fff;
        height: 70px;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        }


    .profils_box label:hover {
        -webkit-box-shadow: 0 2px 40px #e0e5f0;
        -moz-box-shadow: 0 2px 40px #e0e5f0;
        box-shadow: 0 2px 40px #e0e5f0;
        -webkit-transform: translateY(-3px);
        -moz-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        -o-transform: translateY(-3px);
        transform: translateY(-3px);
    }



    .profils_box input[type=checkbox]:not(old),
    .profils_box input[type=radio]:not(old) {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .profils_box input[type=checkbox]:not(old):checked+label,
    .profils_box input[type=radio]:not(old):checked+label,
    .profils_box input[type=checkbox]:not(old):checked+span+label,
    .profils_box input[type=radio]:not(old):checked+span+label {
        display: block;
        border: 1px solid #f98008;
        display: flex;            
        justify-content: center;  
        align-items: center;  

    }

    .profils_box input[type=checkbox]:not(old):checked+label:before,
    .profils_box input[type=radio]:not(old):checked+label:before,
    .profils_box input[type=checkbox]:not(old):checked+span+label:before,
    .profils_box input[type=radio]:not(old):checked+span+label:before {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 24px;       
        height: 24px;      
        background-image: url('https://papipets.tn/wp-content/uploads/2024/04/checked.png'); /* URL de l'image */
        background-size: cover; 
        content: "";
    }

    .profils_box input[type=checkbox]:not(old):checked+label span,
    .profils_box input[type=radio]:not(old):checked+label span,
    .profils_box input[type=checkbox]:not(old):checked+span+label span,
    .profils_box input[type=radio]:not(old):checked+span+label span {
        border: 4px solid #f98008;
    }

    .profils_box input[type=checkbox]:not(old):checked+label figure img,
    .profils_box input[type=radio]:not(old):checked+label figure img,
    .profils_box input[type=checkbox]:not(old):checked+span+label figure img,
    .profils_box input[type=radio]:not(old):checked+span+label figure img {
        -webkit-transform: translate(-50%, -50%) scale(1.2);
        -moz-transform: translate(-50%, -50%) scale(1.2);
        -ms-transform: translate(-50%, -50%) scale(1.2);
        -o-transform: translate(-50%, -50%) scale(1.2);
        transform: translate(-50%, -50%) scale(1.2);
    }

    .item label {
        border-radius: 1.5rem;
        display: block;
        background-color: #3f130f;
        padding: 45px 5px;
        text-align: center;
        margin-bottom: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        position: relative;
        border: 1px solid #d9e1e6;
        font-size: 15px;
        font-size: 0.7575rem;
        color: #fff;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        height: 285px;
    }

    @media (max-width: 991px) {

        .item label {
            padding: 10px;
            display: flex;
            height: auto;
            align-items: center;
            gap: 15px;
            font-size: 9px;
        }
        #signup-form .item label strong { 
             font-size: .725rem !important;
        }
    }

    #livrasion>div>div>.item {
        width: 260px;

    }

    .item label strong {
        text-transform: none;
        display: block;
        font-size: 1.125rem;
        color: #fff;
    }

    .item label:hover {
        -webkit-box-shadow: 0 2px 40px #e0e5f0;
        -moz-box-shadow: 0 2px 40px #e0e5f0;
        box-shadow: 0 2px 40px #e0e5f0;
        -webkit-transform: translateY(-3px);
        -moz-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        -o-transform: translateY(-3px);
        transform: translateY(-3px);
    }

    .item label img {
        display: block;
        margin: 0 auto 15px;
        width: 100px;
    }

    @media (max-width: 767px) {
        .item label img {
            width: 35px;
            height: 30px;
        }
    }

    .item label span {
        border: 4px solid #d9e1e6;
        display: block;
        margin: 0 auto 15px;
        width: 130px;
        height: 130px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        padding: 4px;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .item input[type=checkbox]:not(old),
    .item input[type=radio]:not(old) {
        visibility: hidden;
        width: 0;
        height: 0 !important;
    }

    .item input[type=checkbox]:not(old):checked+label,
    .item input[type=radio]:not(old):checked+label,
    .item input[type=checkbox]:not(old):checked+span+label,
    .item input[type=radio]:not(old):checked+span+label {
        display: block;
        border: 1px solid #f98008;
        -webkit-box-shadow: 0 1px 10px #3f130f important;
        -moz-box-shadow: 0 1px 10px #3f130f;
        box-shadow: 0 1px 10px #3f130f !important;
    }

    .item input[type=checkbox]:not(old):checked+label:before,
    .item input[type=radio]:not(old):checked+label:before,
    .item input[type=checkbox]:not(old):checked+span+label:before,
    .item input[type=radio]:not(old):checked+span+label:before {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 24px;       
        height: 24px;      
        background-image: url('https://papipets.tn/wp-content/uploads/2024/04/checked.png'); /* URL de l'image */
        background-size: cover; 
        content: "";
    }

    .item input[type=checkbox]:not(old):checked+label span,
    .item input[type=radio]:not(old):checked+label span,
    .item input[type=checkbox]:not(old):checked+span+label span,
    .item input[type=radio]:not(old):checked+span+label span {
        border: 4px solid #f98008;
    }

    .item input[type=checkbox]:not(old):checked+label figure img,
    .item input[type=radio]:not(old):checked+label figure img,
    .item input[type=checkbox]:not(old):checked+span+label figure img,
    .item input[type=radio]:not(old):checked+span+label figure img {
        -webkit-transform: translate(-50%, -50%) scale(1.2);
        -moz-transform: translate(-50%, -50%) scale(1.2);
        -ms-transform: translate(-50%, -50%) scale(1.2);
        -o-transform: translate(-50%, -50%) scale(1.2);
        transform: translate(-50%, -50%) scale(1.2);
    }

    input:not([type=checkbox]).validation-error {
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 3px solid #ff1100 !important;
        color: #ff1100;
    }
    input[type=checkbox].validation-error{
        border-bottom: 3px solid #ff1100 !important;
        color: #ff1100;
    }
    .validation-error iframe{
        border-bottom: 3px solid #ff1100 !important;
        color: #ff1100;
    }
}

.steps-3,
.steps-4 {
    background: rgb(228, 165, 108);
    background: -moz-radial-gradient(circle, rgba(228, 165, 108, 1) 0%, rgba(234, 144, 63, 1) 84%);
    background: -webkit-radial-gradient(circle, rgba(228, 165, 108, 1) 0%, rgba(234, 144, 63, 1) 84%);
    background: radial-gradient(circle, rgba(228, 165, 108, 1) 0%, rgba(234, 144, 63, 1) 84%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4a56c", endColorstr="#ea903f", GradientType=1);
}

.steps-5 {
    background-color: #fff3e1;
}
@media screen and (max-width:768px) {
 .steps-0 {
    background-color: #f98008 !important;
 }
}
.steps-0 {
    background-color: var(--bs-brown);
}

.steps-0 .card {
    background-color: var(--bs-orange);
    border-radius: 1.5rem;
}

.steps-1 {
    background-color: #fff3e1 !important;
}

.steps-2 {
    background-color: #fa6754 !important;
}


.form-text {
    margin-top: 0.25rem;
    font-size: 1.0rem;
    font-weight: 700;
    background-color: #f9800859;
    border-radius: 20px;
    padding: 15px 20px;
    color:#000 !important;
}


.btn-whatsapp {
    position: fixed;
    right: 45px;
    bottom: 100px;
}
.btn-whatsapp img {
    width: 60px;
  }