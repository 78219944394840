.apexcharts-radialbar-area {
    stroke-linecap: round;
}
.apexcharts-xcrosshairs.apexcharts-active, .apexcharts-ycrosshairs.apexcharts-active{
    fill: rgba($primary , .04) !important;
}

.admin-chart-1 {
    .apexcharts-data-labels {
        rect {
            width: 10px;
            height: 10px;
            fill: #fff;
            stroke: #fff;
            color: #fff;
        }
    }
    .apexcharts-marker{
        display: none
    }
}
#admin-chart-8 {
    .apexcharts-canvas {
        margin-left: auto;
    }
}