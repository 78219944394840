.main-content {
    .default-header {
        z-index: 1000;
    }

    .content-inner {
        margin-top: 90px;
    }
}

.z-3 {
    z-index: 3 !important;
}

.z-4 {
    z-index: 4 !important;
}

.sidebar {
    .logo-img {
        width: 150px;
    }
}

.navbar {
    .logo-img {
        width: 120px;
    }
}

.logo-img {
    width: 300px;
}

.error-title {
    font-family: "Rubik", sans-serif;
    font-size: 14rem;
}

.blur-me {
    filter: blur(2px);
}

.cursor-pointer {
    user-select: none;
    cursor: pointer;
}

.text-transform-none {
    text-transform: none !important;
}

.item-order-recipe {
    label {
        border-radius: 1.5rem;
        display: block;
        background-color: #3f130f;
        padding: 45px 5px;
        text-align: center;
        margin-bottom: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
        position: relative;
        border: 1px solid #d9e1e6;
        font-size: 15px;
        font-size: 0.7575rem;
        color: #fff;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;

        &:hover {
            -webkit-box-shadow: 0 2px 40px #e0e5f0;
            -moz-box-shadow: 0 2px 40px #e0e5f0;
            box-shadow: 0 2px 40px #e0e5f0;
            -webkit-transform: translateY(-3px);
            -moz-transform: translateY(-3px);
            -ms-transform: translateY(-3px);
            -o-transform: translateY(-3px);
            transform: translateY(-3px);
        }

        strong {
            text-transform: uppercase;
            display: block;
            font-size: .975rem;
            color: #fff;
        }

        img {
            display: block;
            margin: 0 auto 15px;
            width: 100px;
        }

        .special-offer {
            position: absolute;
            top: 16px;
            left: 20px;
        }
    }

    input[type=radio]:not(old) {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    input[type=radio]:not(old):checked+label,
    input[type=radio]:not(old):checked+span+label {
        display: block;
        border: 1px solid #f98008;
    }

    input[type=radio]:not(old):checked+label:before,
    input[type=radio]:not(old):checked+span+label:before {
        font-family: $fa-style-family;
        content: "\f058";
        font-weight: bold;
        position: absolute;
        right: 20px;
        top: 14px;
        font-size: 18px !important;
        font-size: 1.5rem;
        color: #f98008;
        line-height: initial;
    }
}

.item-order-pack {
    .special-offer {
        position: absolute;
        top: 28px;
        left: 28px;
    }
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-30 {
    margin-bottom: 30px;
}

.bg-beige {
    background-color: #fef1e288;
}

.grow-animate-dogs {
    animation: grows 1s ease-in-out infinite alternate; /* Animation properties */
  }
  
  @keyframes grows {
    from {
      transform: scale(1); /* Start at normal size */
    }
    to {
      transform: scale(1.02); /* Grow to 105% */
    }
  }
  .card-link {
    text-decoration: none; /* Remove default underline */
    color:#f98008;
    font-size:18px;
    font-weight: 600;
    transition: all 0.2s ease-in-out; /* Smooth transition for animation */
    animation: growt 1s ease-in-out infinite alternate;
}
    @keyframes growt {
     from {
      color:#f98008;   
     }
     to {
      color: #3f130f
     }
     }
    .card-link:hover {
     text-decoration: underline; /* Add underline on hover */
     color: #3f130f; /* Change color on hover (adjust as needed) */
    }
  
    .grow-animate {
     animation: grow 1s ease-in-out infinite alternate; /* Animation properties */
    }
  
    @keyframes grow {
     from {
      transform: scale(1); /* Start at normal size */
     }
     to {
      transform: scale(1.04); /* Grow to 105% */
     }
    }
.page-order {
    background-color: var(--bs-white) !important;

    * {
        font-family: "Rubik", sans-serif;
        font-optical-sizing: auto;
        box-sizing: border-box;
    }

    .header {
        height: 120px;

        .logo_home {
            height: 100%;
            width: 200px;
            padding: 20px 0;
            margin: auto;
        }
    }

    .section {
        background-color: #fef1e2;
        border-radius: 1.5rem;
    }

    .checked-pack {
        border: 10px solid var(--bs-primary) !important;
        animation: border-pulse 1s linear none;
    }


    .img-repas,
    .img-sante {
        width: 100px;
    }

    figure.image_resized {
        margin: auto;

        img {
            width: 100% !important;
            height: auto !important;
        }
    }

    figure.image-style-side {
        float: right;
    }

    p .image_resized {
        float: left;
        height: auto !important;
    }

    .modal {
        .btn-close {
            box-sizing: content-box;
        }
    }
}
.text-desciptif {
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
}
.page-checkout {
    background-color: #fff3e1 !important;

    *:not(.fa-solid) {
        font-family: 'Rubik', sans-serif;
        box-sizing: border-box;
    }

    .header {
        height: 70px;

        .logo_home {
            height: 100%;
            width: 200px;
            padding: 20px 0;
            margin: auto;
        }
    }

    .payment-section {
        border: 1px solid $border-color;
    }

    .payment-img {
        height: 12px;
        margin-left: 6px;
        margin-bottom: 2px;
    }
}

.animated {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.react-select-container {
    .react-select__control {
        height: 46px;
        border-color: $border-color;

        [class *='-MultiValueRemove']:hover {
            background-color: transparent;
        }

        .react-select__single-value,
        .react-select__placeholder {
            text-overflow: ellipsis;
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .react-select__control:hover {
        border-color: $border-color;
    }

    .react-select__control--is-focused:hover {
        border-color: rgba($primary, .5);
        box-shadow: $input-btn-focus-box-shadow;
    }

    .react-select__control--is-focused {
        border-color: rgba($primary, .5);
        box-shadow: $input-btn-focus-box-shadow;
    }

    .react-select__menu {
        z-index: 1000;
    }
}

.react-select-radius {
    .react-select__control {
        border-radius: 1.25rem;

        .react-select__multi-value {
            border-radius: 1.25rem;
        }
    }
}

.react-select-multiple {
    .react-select__control {
        min-height: 46px;
        height: auto;
    }
}

.required-input::after {
    content: "*";
    margin-left: 2px;
    color: $pink;
}

.is-invalid .react-select__control {
    border-color: $red !important;
}

.label-bordered {
    border-left: 3px solid $primary;
    padding: 10px 24px;
    background: #fcfcfc;
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.input-text-warning {
    font-size: 12px;

    i {
        color: $info;
        margin-right: 1px;
    }
}

.react-date-picker__wrapper {
    border: none !important;
}

.ck-editor__editable_inline:not(.ck-comment__input *) {
    height: 300px;
    overflow-y: auto;
}

.btn-promo-code {
    width: 70px;
    pointer-events: none;
    user-select: none;
}

.card-wlc-client {
    min-height: 320px;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(250, 251, 233, 0.50) 44%, rgba(250, 251, 233, 1) 59%);
    border-radius: 1.5rem;

    .circle-group {
        opacity: 0.1;
        user-select: none;

        .cricle1 {
            background-image: url(../../images/shapes/cercle-top-left.svg);
            background-repeat: no-repeat;
            background-size: 230px;
            background-position: -58px -58px;
            border-radius: 1.5rem;
            z-index: -1;
        }

        .cricle2 {
            background-image: url(../../images/shapes/cercle-bottom-left.svg);
            background-repeat: no-repeat;
            background-size: 320px;
            background-position: -94px 94px;
            border-radius: 1.5rem;
            z-index: -2;
        }
    }

    .right-shape {
        background-image: url(../../images/shapes/shape1.svg);
        background-repeat: no-repeat;
        background-size: 375px;
        background-position: top -68px right -78px;
        border-radius: 1.5rem;
        user-select: none;
    }
}

.text-justify {
    text-align: justify !important;
}

.order-dash-details {
    zoom: normal;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.rdt_Table {
    font-size: 14px;
    font-family: Rubik, Helvetica, sans-serif;

    .rdt_TableHeadRow {
        white-space: nowrap;
        color: $primary;
        border-bottom: 2px solid #959895;

        .rdt_TableCol {
            font-size: 16px;
            font-weight: 500;
            padding: $table-th-padding-y $table-th-padding-x;
            text-transform: capitalize;
            letter-spacing: $table-th-letter-spacing;
        }

        .rdt_TableCol_Sortable {
            .__rdt_custom_sort_icon__.desc {
                color: #07143B;

                .sort-icon::after {
                    font-family: $fa-style-family;
                    content: "\f160";
                    font-weight: bold;
                    font-size: 12px;
                    margin-left: 2px;
                    position: absolute;
                    top: 15px;
                }
            }

            .__rdt_custom_sort_icon__.asc {
                color: #07143B;

                .sort-icon::after {
                    font-family: $fa-style-family;
                    content: "\f885";
                    font-weight: bold;
                    font-size: 12px;
                    margin-left: 2px;
                    position: absolute;
                    top: 15px;
                }
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            font-size: 14px;
            padding: 10px 0;

            .rdt_TableCell {
                div:first-child {
                    white-space: pre-line;
                }
            }

            .svg-white {
                padding: 4px 2px;
                border-radius: 50%;
                cursor: pointer;

                path {
                    stroke: $white;
                }
            }

            .custom-svg-white {
                path {
                    fill: $white;
                    stroke: none;
                }
            }

            .svg-dark {
                padding: 4px 2px;
                border-radius: 50%;
                cursor: pointer;

                path {
                    stroke: $dark;
                }
            }

            .custom-svg-dark {
                path {
                    fill: $dark;
                    stroke: none;
                }
            }

            &:hover {
                background-color: rgb(250 249 245 / 70%);
            }
        }
    }
}

.rdt_Pagination {
    padding: 16px 20px 0 20px !important;

    button {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background-color: #fff;
        border: 1px solid #dee2e6;
        margin: 0 2px;

        svg {
            fill: $primary;
        }

        &:hover:not(:disabled) {
            background-color: #e9ecef;
            border-color: #dee2e6;

            svg {
                fill: #bb550e;
            }
        }
    }
}

.search-input {
    width: auto;

    &.input-group {
        .input-group-text {
            padding-right: 0;
            border-right: 0;
        }

        .form-control {
            border-left: 0;

            &:focus {
                box-shadow: unset;
                border-color: $input-border-color;
            }
        }

        &:focus-within {

            .input-group-text,
            .form-control {
                border-color: $input-focus-border-color;
            }

            box-shadow: $component-active-shadow;
        }
    }
}

.badge {
    white-space: pre-line;
}

.z-n1 {
    z-index: -1;
}

.z-1 {
    z-index: 1;
}

.ff-base {
    font-family: $font-family-base;
}

sup.dash-value {
    top: -1rem;
}

.grecaptcha-badge {
    visibility: hidden;
}

.custom-dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;

        .dropdown-item.active {
            background-color: $light;
            color: $dark;
        }

        .dropdown-item {
            &:focus {
                background-color: $light;
                color: $dark;
            }
        }
    }
}

.bg-loading {
    background-color: rgba($dark, .5);
}

.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
        display: flex;
        align-items: center;

        .react-datepicker__calendar-icon {
            right: 6px;
        }

        input {
            height: 46px;
        }
    }
}

.validation-error:not(.select-group) {
    border: 1px solid #f44336 !important;
}
.validation-error .react-select__control{
    border: 1px solid #f44336;
}

.input-group .right-btn-group {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
}

.btn-password-group {
    color: $dark;
    background-color: $white;

    &:hover {
        color: $white;
        background-color: rgba($dark, .5);
    }
}

.card header{
    margin-bottom: 0.5rem !important;
}
.react-select-rounded .react-select__control{
    border-radius: 1.25rem;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes border-pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

@include media-breakpoint-down(lg) {
    .error-title {
        font-size: 10rem;
    }

    .card-wlc-client {
        .right-shape {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .error-title {
        font-size: 8rem;
    }
}

@include media-breakpoint-down(sm) {
    .error-title {
        font-size: 6rem;
    }

    .card-wlc-client {
        .circle-group {
            .cricle1 {
                background-size: 260px;
            }

            .cricle2 {
                background-size: 334px;
                background-position: -94px 136px;
            }
        }
    }

    .dog-img-dash {
        top: auto !important;
    }

    .order-dash-details {
        zoom: 0.7;
    }
}

@include media-breakpoint-down(xl) {
    .sidebar {
        .logo-img {
            width: 120px;
        }
    }

    .col-lg-8 {
        .card-wlc-client {
            .right-shape {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .main-content {
        .navbar-list {
            margin-right: 5rem;
        }
    }
}

span.prix-aff {
    font-weight: 400;
    color: #fff;
    gap: 9px !important;
    background-color:#3f130f;
    padding: 7px 13px;
    border-radius: 15px;
}