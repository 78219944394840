.checkout-panel {
  .stepper {
    display: flex;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease
  }

  .step {
    position: relative;
    z-index: 1;
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 1rem;
    border-radius: 50%;
    line-height: 40px;
  }

  .step {
    background-color: rgba($light, 0.5);

    svg {
      path {
        fill: $secondary;
      }
    }

    &:after {
      position: absolute;
      z-index: -1;
      top: 18px;
      left: 40px;
      width: 310px;
      height: 6px;
      content: '';
      background-color: $light;
    }

  }

  .step.active {
    background-color: rgba($primary, 0.1);

    svg {
      path {
        fill: $primary;
      }
    }

    &:after {
      background-color: $primary;
    }
  }

  .step.done {
    background-color: rgba($success, 0.1);

    svg {
      path {
        fill: $success;
      }
    }

    &:after {
      background-color: $success;
    }
  }

  .step.cancel {
    background-color: rgba($danger, 0.1);

    svg {
      path {
        fill: $danger;
      }
    }
  }

  .step:last-child:after {
    content: none;
  }
}

@include media-breakpoint-down(xl) {
  .checkout-panel {
    .step {
      &:after {
        width: 175px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .checkout-panel {
    .step {
      &:after {
        width: 200px;
      }
    }
  }

  .checkout-panel {
    .stepper {
      display: none;
    }

  }
}