.card {
    margin-bottom: $grid-gutter-width;
	background: $white;
	box-shadow: rgba(7, 20, 59, 0.2) 0px 0px 1px 0px;
    backdrop-filter: blur(42px);
    .card-header {
		border-bottom: 1px solid $border-color;
		margin: 0 1.5rem;
		.header-title {
			.card-title {
				margin-bottom: 0;
			}
		}
	}
	&.card-white{
		background: $white;
	}
	&.card-primary{
		background: $primary;
	}
}
.card-header {
    .card-title {
        margin-bottom: 0;
    }
}
.heading-title{
	font-family: 'rubik', sans-serif;
	font-weight: 400;
}