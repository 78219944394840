.table {
    thead {
        white-space: nowrap;
        tr {
            border-bottom:1px solid;
            color:$primary;
            th {
                padding: $table-th-padding-y $table-th-padding-x;
                text-transform: capitalize;
                letter-spacing: $table-th-letter-spacing;
            }
        }
    }
    tbody {
        tr {
            td {
               color: $table-td-color;
               vertical-align: middle;
            }
        }
    }
    &.table-dark{
        tbody {
            tr {
                td {
                   color: $body-bg;
                   vertical-align: middle;
                }
            }
        }
    }
    overflow: hidden;
    &.user-list-table>:not(caption)>*>* {
            padding: 1rem 0rem;
        }
       &.product-table{
            thead{
                background-color: $primary !important;
            }
        }
}
// .table > :not(:last-child) > :last-child > * {
//     border-bottom-color: transparent;
// }
.table-shadow tr:last-child td  {
    border: none;
    box-shadow: unset;
    border-bottom: none;
}
.card{
    .iq-dashed-border{
        padding: $card-spacer-y;
        border-radius: $card-border-radius;
        border: $border-width dashed $gray-400;
    }
    .card-body{
        .grid-flow-col {
            grid-auto-flow: column;
        }
        .iq-icon-box-2 {
            height: 36px;
            width: 36px;
            min-width: 36px;
            line-height: 36px;
            background: $body-bg;
            text-align: center;
            font-size: 1rem;
            text-decoration: none;
            border-radius: 0.5rem;
            @if $enable-rounded == true {
                // @include radius-mixin($border-radius);
            }
        }   
        .iq-media-group-1 {
            .iq-media-1 {
                margin-left:-1.25rem;
                position: relative;
                z-index: 0;
                @if $enable-transitions == true {
                    // @include transition-mixin(all 0.3s ease-in-out);
                }
                &:hover {
                    z-index: 9;
                }
                img {
                    border: 2px solid $border-color;
                }
                &:first-child {
                    margin-left: 0;
                }
            }
            .iq-media.border {
                border: 2px solid $border-color;
            }
        }
        .iq-media-1 {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            &:hover {
                text-decoration: none;
            }
            border-radius: $border-radius-sm;
        }
    }
}

.table-responsive{
    .dataTables_wrapper{
        .row{
            .dataTables_length{
                padding-left: $spacer * 1.5;
            }
            .dataTables_filter, .dataTables_paginate{
                padding-right: $spacer * 1.5;
            }
            .dataTables_info{
                padding-left: $spacer * 1.5;
                padding-top: unset;
            }
        }
    }
}
.iq-table{
    thead {
        tr th{
            border-bottom-color: $border-color !important;
        }
    }
    tbody {
        tr {
            td {
                border-bottom: 1px solid rgba($primary, 0.09);
            }
            &:last-child{
                td{
                    border-bottom: unset;
                }
            }
        }
    }
}
.table-dark{
    td {
        color: $body-bg !important;
        vertical-align: middle;
    }
}

thead, tbody, tfoot, tr, td, th{
    white-space: nowrap;
}
