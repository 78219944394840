
// .nav-tabs {
//     @include border-radius($nav-tabs-border-radius);
//     margin-bottom: .5rem;
//     .nav-link {
//       @include border-radius($nav-tabs-border-radius);
//       &.active{
//         @include box-shadow($box-color-shadow rgba($primary, $box-color-shadow-tint));
//       }
//     }
// }

.nav-tabs {
  margin-bottom: .5rem;
  background: transparent !important;
  border-bottom: 1px solid $border-color;
  .nav-link {
    background: transparent !important;
    &.active{
      border-bottom: 2px solid $primary;
    }

  }
}

.nav-tabs {
  margin-bottom: .5rem;
  background: transparent !important;
  border-bottom: 1px solid $border-color;
  &.product-tab{
    .nav-link {
      position: relative;
      &::before {
        content: "";
        border-bottom: 4px solid $primary;
        position: absolute;
        bottom: 0;
        width: 50%;
        border-radius: 2px;
        opacity: 0;
        transition: all 400ms ease;
      }
      &.active {
        background: transparent;
        border:none;
        &::before {
          opacity: 1;
        }
      }
    }
  }

}




.nav-pills {
  @include border-radius($nav-pills-border-radius);
  margin-bottom: .5rem;
}

.nav-tunnel {
  padding: $spacer * .25;
  @include box-shadow($box-shadow-inset);
  background: $gray-200;
  .nav-link {
    padding: $spacer * .25 1rem;
  }
}