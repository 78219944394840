.menu-card{
    position: relative;
    &::after{
        border-right: 6px solid $secondary;
        position: absolute;
        content: "";
        height: 76px;
        border-radius: 10px 0px 0px 10px;
        right: 0px;
        top: 38px;
    }

}
.menu-card-2{
    position: relative;
    &::after{
        border-right: 6px solid $success;
        position: absolute;
        content: "";
        height: 76px;
        border-radius: 10px 0px 0px 10px;
        right: 0px;
        top: 38px;
    }

}
.menu-card-3{
    position: relative;
    &::after{
        border-right: 6px solid $primary;
        position: absolute;
        content: "";
        height: 76px;
        border-radius: 10px 0px 0px 10px;
        right: 0px;
        top: 38px;
    }

}
.iq-fetch{
    .iq-fetch-details{
        margin: 0 8px 8px 0;
    }
}