@mixin circle-clip-effect($color) {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $color;
      clip-path: circle(0% at 50% 50%);
      transition: clip-path 500ms ease;
      z-index: -1;
    }
    &:hover {
      &:after {
        transition: clip-path 500ms ease;
        clip-path: circle(100%);
      }
    }
}