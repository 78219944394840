.card-wiget-list {
    .card-progress {
        .circle-progress {
            width: 3rem;
            height: 3rem;

            div {
                position: absolute;
                width: 3.125rem;
                text-align: center;
                height: 3.125rem;
                display: flex;
                place-content: center;
                align-items: center;
            }

            .circle-progress-text {
                display: none;
            }
        }
    }
}

.category-menu {
    transition: all 400ms ease;

    // opacity: 70%;
    .heading-title {
        position: relative;
        transition: all 400ms ease;

        &::before {
            content: "";
            border-bottom: 1px solid $primary;
            width: 32px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0%;
            margin: 0 auto;
            text-align: center;
            transition: all 400ms ease;
        }
    }

    .iq-menu-category {
        .rounded-svg {
            background-color: $primary;
            padding: 4px 2px;
            border-radius: 50%;
            cursor: pointer;

            rect {
                fill: $primary;
            }

            path {
                stroke: $white;
            }
        }

        .custom-rounded-svg{
            path {
                fill: $white;
                stroke: none;
            }
        }
    }

    &:hover {
        background-color: rgba($primary, 0.7);

        .heading-title {
            color: $white;

            &::before {
                border-color: $white;
                width: 100%;
            }
        }

        .heading-description{
            color: $white;
        }

        .iq-menu-category {
            .rounded-svg {
                background-color: $white;
                rect {
                    fill: $white;
                }

                path {
                    stroke: $primary;
                }
            }

            .custom-rounded-svg{
                path {
                    fill: $primary;
                    stroke: none;
                }
            }
        }
    }

    &.active {
        background-color: rgba($primary, 0.7);

        .heading-title {
            color: $white;

            &::before {
                border-color: $white;
                width: 100%;
            }
        }
    }
}

.custom-category-menu {
    height: 100%;
    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 150px;
            height: 150px;
            background-color: $gray-100;
            border: 4px solid $brown;
            object-fit: cover;
        }
    }
    &:hover {
        background-color: $primary;

        img{
            background-color: $white;
        }
    }
}

.add-new-item {
    height: 100%;
    cursor: pointer;

    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        path {
            stroke: $gray-200;
        }
    }

    &:hover {
        background-color: $white;
    }
}

.iq-calories {
    position: relative;
    transition: all 400ms ease;

    &::before {
        content: "";
        border-bottom: 1px solid $border-color;
        width: 32px;
        position: absolute;
        left: 0;
        bottom: 12px;
        margin: 0 auto;
        text-align: center;
        transition: all 400ms ease;
    }
}