img.img-user {
    position: fixed;
    left: auto;
    right: -3%;
    top: auto;
    bottom: 0;
    z-index: -1;
}
.user-img1{
    position: fixed;
    top: 280px;
}